.App {
  text-align: center;
}

.App-logo {
  height: 5vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-header p {
  font-size: calc(20px + 2vmin);
}

.App-body {
  background-color: #282c34;
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-body p {
  font-size: calc(40px + 2vmin);
}

.App-header a {
  text-decoration: none;
  color: white;
  font-size: calc(10px + 2vmin);
  margin-left: 1vmin;
  margin-right: 1vmin;
}

.App-header a:hover {
  color:grey;
}

.App-link {
  color: #61dafb;
}

.App-footer {
  background-color: #282c34;
  padding-bottom: 3em;
  color: white;
}

.footer-text {
  font-size: calc(2.5px + 2vmin);
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}